.mobileNavigation {
  display: none;
}
.mobileNavigation ul li a:hover {
  border-radius: 0px;
  background-color: transparent;
  color: white;
  transition: ease 0.5s;
  box-shadow: none;
  display: block;
  width: 100%;
  font-weight: bold;
}
@media only screen and (max-width: 750px) {
  .mobileNavigation {
    display: flex;
    align-items: center;
  }
  .mobileNavigation ul {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 215px;
    z-index: 100;
    list-style: none;
    right: 0;
    width: 100%;
    background-color: #056da5cb;
    padding-left: 5%;
  }
  .mobileNavigation ul li {
    padding: 5px;
    margin: 0;
    width: 100%;
  }
  .mobileNavigation ul li a {
    color: white;
    font-size: 20px;
    text-decoration: none;
  }
  .mobileNavigation .menu-icon {
    position: absolute;
    font-size: 25px;
    right: 5%;
    cursor: pointer;
  }
}
@media only screen and (max-width: 600px){
    .mobileNavigation ul {
      top: 205px;
    }
      /* MobileNav.jsx */
  }