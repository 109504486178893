.titleForm{
    margin-bottom: 1%;
}
/* Container padre */
.MuiContainer-root.MuiContainer-maxWidthLg.css-1oqqzyl-MuiContainer-root {
    margin-top: 5%; 
    width: 80%;
}
.containerInputs{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
}
.outlined-basic-mensaje { grid-column: 1/3; }
.send{
    grid-column: 2/3;
    justify-self: end;
} 

/* formato input */
.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 15px;
}
/* boton */
button.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButtonBase-root.send.css-sghohy-MuiButtonBase-root-MuiButton-root {
    width: 25%;
    border-radius: 30px;
}

@media only screen and (max-width: 600px) {
    .titleForm{
        font-size: 20px;
    }
    .containerInputs{
        grid-template-columns: 1fr;
    }
    .outlined-basic-mensaje { grid-column: 1/2; }
    .send{grid-column: 1/2;} 
}
