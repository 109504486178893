.hero{
  min-height: 300px;
  max-height: 500px;
  position: relative;
}
.hero img {
  width: 100%;
  height: auto;
  box-shadow: 4px 4px 8px rgb(255, 255, 255);
  min-height: 300px;
  max-height: 500px;

}
.hero_text {
  position: absolute;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: justify;
  width: 100%;
  max-width: 1100px;
}
.hero_text h2 {
  font-size: 30px;
  padding: 4% 8%;
}
.btn-hero {
  border-radius: 30px;
  background-color: var(--azul-links);
  color: white;
  box-shadow: 4px 4px 7px rgba(150, 150, 150, 1);
  text-decoration: none;
  padding: 10px 30px;
  font-weight: 500;
  margin-left: 5%;
}
.middle {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
}
.middle_img {
  width: 55%;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.middle_img img {
  width: 70%;
  min-width: 300px;
}
.middle_text {
  width: 45%;
  padding: 30px;
  letter-spacing: 0.5px;
}
.middle_text h3{
  font-size: 25px;
}
.middle_text p{
  margin-top: 10px;
  font-size: 18px;
}
.services {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  max-width: 1000px;
  margin: 0 auto;
}
.services_title {
  width: 30%;
  font-size: 30px;
}
.services_list {
  width: 70%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  font-size: 25px;
}
.services_list-serv {
  box-shadow: 4px 4px 7px rgba(150, 150, 150, 1);
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1%;
}
.clients {
  margin-top: 3%;
  margin-bottom: 1%;
  background: linear-gradient(
    90deg,
    var(--verde-gradient) 0%,
    var(--azul-gradient) 100%
  );
  max-height: 300px;
  overflow-y: scroll;
  text-align: center;
}
.clients h3 {
  color: white;
  padding: 3%;
  font-size: 30px;
}
.clients_images {
  max-width: 1200px;
  margin: 0 auto;
}
.clients_images img {
  width: 250px;
  margin: 1% 2%;
}

@media only screen and (max-width: 600px) {
  .hero_text h2 {
    font-size: 22px;
  }
  .middle {
flex-direction: column-reverse;
  }
  .middle_img {
    width: 100%;
  }
  .middle_img img {
    width: 70%;
    min-width: 300px;
  }
  .middle_text {
    width: 100%;
  }
  .services {
flex-direction: column;
  }
  .services_title {
    margin-bottom: 30px;
    width: 100%;
  }
  .services_title h3{
    text-align: center;
  }
  .services_list {
    width: 100%;
    margin-bottom: 30px;
  }
  .services_list-serv {
    margin: 0 auto;
    min-width: 290px;
    min-height: 290px;
    /* min-width: 320px;
    min-height: 320px; */
  }
}

@media only screen and (min-width: 768px) {}

@media only screen and (min-width: 992px) {}

@media only screen and (min-width: 1200px)  {
  .middle {
    margin-top: 40px;
  }
}