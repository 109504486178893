.navigation ul {
  display: flex;
  list-style: none;
}
.navigation ul li a {
  text-decoration: none;
  padding: 1% 3%;
  color: black;
  font-weight: 500;
  padding: 10px 20px;
}
.navigation ul li a:hover {
  border-radius: 30px;
  background-color: var(--azul-links);
  color: white;
  transition: ease 0.5s;
  box-shadow: 4px 4px 7px rgba(150, 150, 150, 1);
}
@media only screen and (max-width: 750px) {
    .navigation {
        display: none;
      }
}