.containerTitle{
    width: 100%;
    background-image: url("/img/fondo-titulo.png");
    color: #fff;
    text-align: center;
}

.containerTitle h1{
    padding: 1rem;
}
@media only screen and (max-width: 750px) {
    .containerTitle h1{
        font-size: 25px;
    }
}