@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*FUENTE ROBOTO*/
:root{
  --azul: #056EA5;
  --azul-links: #1877F2;
  --azul-gradient: rgba(65, 100, 132, 1);
  --verde-gradient: rgba(43, 90, 58, 1);
}
*{
  padding: 0;
  margin: 0px;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

.hero{
  min-height: 300px;
  max-height: 500px;
  position: relative;
}
.hero img {
  width: 100%;
  height: auto;
  box-shadow: 4px 4px 8px rgb(255, 255, 255);
  min-height: 300px;
  max-height: 500px;

}
.hero_text {
  position: absolute;
  color: white;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: justify;
  width: 100%;
  max-width: 1100px;
}
.hero_text h2 {
  font-size: 30px;
  padding: 4% 8%;
}
.btn-hero {
  border-radius: 30px;
  background-color: var(--azul-links);
  color: white;
  box-shadow: 4px 4px 7px rgba(150, 150, 150, 1);
  text-decoration: none;
  padding: 10px 30px;
  font-weight: 500;
  margin-left: 5%;
}
.middle {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
}
.middle_img {
  width: 55%;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.middle_img img {
  width: 70%;
  min-width: 300px;
}
.middle_text {
  width: 45%;
  padding: 30px;
  letter-spacing: 0.5px;
}
.middle_text h3{
  font-size: 25px;
}
.middle_text p{
  margin-top: 10px;
  font-size: 18px;
}
.services {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  max-width: 1000px;
  margin: 0 auto;
}
.services_title {
  width: 30%;
  font-size: 30px;
}
.services_list {
  width: 70%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  font-size: 25px;
}
.services_list-serv {
  box-shadow: 4px 4px 7px rgba(150, 150, 150, 1);
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1%;
}
.clients {
  margin-top: 3%;
  margin-bottom: 1%;
  background: linear-gradient(
    90deg,
    var(--verde-gradient) 0%,
    var(--azul-gradient) 100%
  );
  max-height: 300px;
  overflow-y: scroll;
  text-align: center;
}
.clients h3 {
  color: white;
  padding: 3%;
  font-size: 30px;
}
.clients_images {
  max-width: 1200px;
  margin: 0 auto;
}
.clients_images img {
  width: 250px;
  margin: 1% 2%;
}

@media only screen and (max-width: 600px) {
  .hero_text h2 {
    font-size: 22px;
  }
  .middle {
flex-direction: column-reverse;
  }
  .middle_img {
    width: 100%;
  }
  .middle_img img {
    width: 70%;
    min-width: 300px;
  }
  .middle_text {
    width: 100%;
  }
  .services {
flex-direction: column;
  }
  .services_title {
    margin-bottom: 30px;
    width: 100%;
  }
  .services_title h3{
    text-align: center;
  }
  .services_list {
    width: 100%;
    margin-bottom: 30px;
  }
  .services_list-serv {
    margin: 0 auto;
    min-width: 290px;
    min-height: 290px;
    /* min-width: 320px;
    min-height: 320px; */
  }
}

@media only screen and (min-width: 768px) {}

@media only screen and (min-width: 992px) {}

@media only screen and (min-width: 1200px)  {
  .middle {
    margin-top: 40px;
  }
}
.containerFooter {
  color: #fff;
  background: linear-gradient(
    100.66deg,
    rgba(8, 63, 26, 0.86) 23.13%,
    rgba(8, 63, 26, 0.92) 23.14%,
    rgba(8, 63, 26, 0.86) 23.14%,
    rgba(8, 63, 26, 0.86) 43.82%,
    #056ea5 95.16%
  );
  display: flex;
  flex-direction: column;
}
.containerFooter a {
  color: #fff;
  text-decoration: none;
}
.containerFooter-up {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}
.containerFooter-down {
  width: 100%;
  text-align: center;
  border-top: 1px solid white;
  padding: 1rem;
}

.containerFooter img {
  width: 75px;
  min-width: 75px;
}
.box {
  width: 30%;
  /* height: 250px; */
}
.email {
  display: flex;
  justify-content: center;
  padding: 1rem;
  text-align: center;
}
.iconsRedes {
  padding: 0.3rem;
}
.center {
  text-align: center;
}
.services_footer ul {
  list-style: none;
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    align-items: center;
}
.services_footer h2{
    text-align: center;
  }
.copyright{
    font-size: 14px;
}
@media only screen and (max-width: 700px) {
  .containerFooter-up {
    flex-direction: column;
    align-items: center;
  }
  .box {
    width: 80%;
  }
  .services_footer ul {
      list-style: circle;
      margin-bottom: 1rem;
}
.services_footer a{
  display: block;
  padding: .25rem;
}

  .contactUs{
    margin: 1rem 0;
  }
}
@media only screen and (max-width: 550px){
    .box {
        width: 100%;
      }
}
.containerTitle{
    width: 100%;
    background-image: url("/img/fondo-titulo.png");
    color: #fff;
    text-align: center;
}

.containerTitle h1{
    padding: 1rem;
}
@media only screen and (max-width: 750px) {
    .containerTitle h1{
        font-size: 25px;
    }
}
.aboutUs-header {
  background-image: url(/img/fondoTitulo.png);
  height: 100px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content {
  display: flex;
  max-width: 1000px;
  margin: 25px 0;
}
.content-left {
  width: 30%;
  text-align: center;
  margin: 10px 0;
  margin-right: 20px;
}
.content-left img {
  width: 150px;
  height: auto;
  box-shadow: 0px 3px 2px rgba(150, 150, 150, 1);
  padding: 40px 20px;
  border-radius: 10px;
}
.content-right {
  width: 70%;
  padding: 20px;
}

ul {
  margin-top: 10px;
  margin-left: 20px;
}
ul li{
  padding-top: 5px;
}

@media only screen and (max-width: 600px) {
  .content {
    flex-direction: column;
    text-align: justify;
    margin-bottom: 0px;

  }
  .content-left {
    width: 100%;
  }
  .content-right {
    width: 100%;
  }
  ul {
    margin-top: 20px;
    padding-right: 20px;
  }
}

.solutions-header {
  background-image: url(/img/fondoTitulo.png);
  height: 100px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content {
  display: flex;
  max-width: 1000px;
  margin: 25px 0;
}
.content-left {
  width: 30%;
  text-align: center;
  margin: 10px 0;
  margin-right: 20px;
}
.content-left img {
  width: 150px;
  box-shadow: 0px 3px 2px rgba(150, 150, 150, 1);
  padding: 40px 20px;
  border-radius: 10px;
}
.content-right {
  width: 70%;
  padding: 20px;
}
ul {
  margin-left: 20px;
}

@media only screen and (max-width: 600px) {
  .content {
    flex-direction: column;
    text-align: justify;
    margin-bottom: 0px;
  }
  .content-left {
    width: 100%;
  }
  .content-right {
    width: 100%;
  }
  ul {
    margin-top: 20px;
    padding-right: 20px;
  }
}

section {
    text-align: justify;
}

.imgOperators{
    width: 100%;
    height: auto;
}

.imgRack{
    width: 100%;
    height: auto;
    margin-top: 2%;
    border-radius: 15px;
}

.imgHelmets{
    width: 100%;
    height: auto;
    margin-top: 2%;
    margin-bottom: 5%;
    border-radius: 15px;
}
.containerProjects{
    margin: 2% 3% 2% 4%; 
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 1em;
    gap: 1em;
}
.h1{
    grid-column: 1/2; 
    margin: 2%;
}
.h3{grid-column: 1/2;
    margin: 2%
}
.p{
    grid-column: 1/2; 
    margin: 2%; 
    text-align: justify;
}
.imgProjects{
    grid-column: 2/3; 
    height: 60vh;
    padding: 10px;}

.scrollImg{
    overflow: auto;
    white-space: nowrap;
}
@media only screen and (max-width: 600px) {
    .containerProjects{
        grid-template-columns: 1fr;
    }
    .imgProjects{
        height: 50vh;
    }
}
.containerEmail{
    margin-left: 14%;
    margin-bottom: 3%;
    line-height: 1.6;
}
.titleForm{
    margin-bottom: 1%;
}
/* Container padre */
.MuiContainer-root.MuiContainer-maxWidthLg.css-1oqqzyl-MuiContainer-root {
    margin-top: 5%; 
    width: 80%;
}
.containerInputs{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
    gap: 1em;
}
.outlined-basic-mensaje { grid-column: 1/3; }
.send{
    grid-column: 2/3;
    justify-self: end;
} 

/* formato input */
.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 15px;
}
/* boton */
button.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButtonBase-root.send.css-sghohy-MuiButtonBase-root-MuiButton-root {
    width: 25%;
    border-radius: 30px;
}

@media only screen and (max-width: 600px) {
    .titleForm{
        font-size: 20px;
    }
    .containerInputs{
        grid-template-columns: 1fr;
    }
    .outlined-basic-mensaje { grid-column: 1/2; }
    .send{grid-column: 1/2;} 
}

.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--azul);
  padding: 20px 80px;
  color: white;
}
.nav-header_media:nth-child(1) {
  border-left: 1px solid white;
  padding-left: 20px;
}
.nav-header_contact {
  width: 60%;
  display: flex;
  justify-content: space-between;
}
.contact_location {
  display: flex;
}
.contact_mail {
  display: flex;
}
.nav-footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
}
.nav-icon {
  width: 20px;
  height: 20px;
  margin: 0 10px;
  color: white;
}
.nav-logo {
  width: 70px;
  height: auto;
}
.nav-footer_links {
  display: flex;
  justify-content: space-around;
  width: 80%;
}

.selected {
  border-radius: 30px;
  background-color: var(--azul-links);
  color: white !important;
  box-shadow: 4px 4px 7px rgba(150, 150, 150, 1);
}

@media only screen and (max-width: 750px) {
  .nav-header {
    flex-direction: column-reverse;
    padding: 0;
    text-align: center;
  }
  .nav-header_media {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-right: 5%;
  }
  .nav-header_media:nth-child(1) {
    border-left: 0;
    padding-left: 0px;
    padding: 10px;
  }
  .nav-header_contact {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    margin-right: 5%;
    width: 100%;
  }
  .nav-header_contact div {
    padding: 10px;
  }
  .selected {
    border-radius: 0px;
    background-color: transparent;
    color: white !important;
    box-shadow: none;
    font-weight: bold;
  }
}

@media only screen and (max-width: 400px) {
  .nav-header_contact {
    flex-direction: column;
  }
}

.mobileNavigation {
  display: none;
}
.mobileNavigation ul li a:hover {
  border-radius: 0px;
  background-color: transparent;
  color: white;
  transition: ease 0.5s;
  box-shadow: none;
  display: block;
  width: 100%;
  font-weight: bold;
}
@media only screen and (max-width: 750px) {
  .mobileNavigation {
    display: flex;
    align-items: center;
  }
  .mobileNavigation ul {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 215px;
    z-index: 100;
    list-style: none;
    right: 0;
    width: 100%;
    background-color: #056da5cb;
    padding-left: 5%;
  }
  .mobileNavigation ul li {
    padding: 5px;
    margin: 0;
    width: 100%;
  }
  .mobileNavigation ul li a {
    color: white;
    font-size: 20px;
    text-decoration: none;
  }
  .mobileNavigation .menu-icon {
    position: absolute;
    font-size: 25px;
    right: 5%;
    cursor: pointer;
  }
}
@media only screen and (max-width: 600px){
    .mobileNavigation ul {
      top: 205px;
    }
      /* MobileNav.jsx */
  }
.navigation ul {
  display: flex;
  list-style: none;
}
.navigation ul li a {
  text-decoration: none;
  padding: 1% 3%;
  color: black;
  font-weight: 500;
  padding: 10px 20px;
}
.navigation ul li a:hover {
  border-radius: 30px;
  background-color: var(--azul-links);
  color: white;
  transition: ease 0.5s;
  box-shadow: 4px 4px 7px rgba(150, 150, 150, 1);
}
@media only screen and (max-width: 750px) {
    .navigation {
        display: none;
      }
}
